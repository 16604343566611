<template>
  <div>
    <div class="my-offer-head">
      These are the offers that you have participated in so far.
    </div>
    <!-- div class="my-offer-head">Wed, 09 July 2021</div-->
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';
@Component
export default class MyOfferHeader extends Vue {}
</script>

<style lang="scss" scoped>
.my-offer-head {
  @include flex-row-justify(flex-start);
  @include apply-font($font-noto-sans-kr, normal, normal, 13px, 150%);
  width: 100%;
  height: 40px;
  color: #737373;
  margin: 15px 0px;
}
</style>
